// 1. Import the functions
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
// import MobileDetect from 'mobile-detect'; 

$(document).ready(function () {
    let _this = $(this);
    // Deteactamos si el dispositivo es mobile o desktop 
    function isMobileDevice() {
        return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };


    // Google Analytics
    if ($('.btn-show-detail').length > 0) {
        $(document).on('click', '.btn-show-detail', function(e){
            e.stopPropagation();
            productClick($(this).attr('data-analytics'), $(this).attr('data-url'));
        });
        
    }
    if ($('.btn-add-favorito').length > 0) {
        $(document).on('click', '.btn-add-favorito', function (e) {
            e.stopPropagation();
            var name = $(this).attr('data-name');
            dataLayer.push({
                'event': 'Favoritos',
                'eventLabel': name
            });
        });

    }
    function productClick(obj,url) {
        var objOk = obj.replace(/'/g, "\"");
        var productObj = JSON.parse(objOk);
         
        dataLayer.push({
            'event': 'productClick',
            'ecommerce': {
                'currencyCode': 'EUR', // Tipo de moneda.
                'click': {
                    'actionField': { 'list': productObj.list, }, // Título de la lista donde se imprimió el producto
                    'products': [{
                        'name': productObj.name, // Nombre del producto
                        'id': productObj.id, // ID del producto
                        'price': productObj.price, // Precio del producto
                        'brand': productObj.brand, // Marca del producto
                        'category': productObj.category, // Categoría del producto
                        'variant': productObj.variant, // Variante del producto
                        'position': productObj.position // Posición que ocupa en la lista donde se imprime
                    }]
                }
            },
            'eventCallback': function () {
                document.location = url // La url de destino
            }
        });
    }
    if ($('.btn-analytics-add').length > 0) {
        $(document).on('click', '.btn-analytics-add', function (e) {
            // e.stopPropagation();
            productAdd($(this).attr('data-analytics'));
        });
        function productAdd(obj){
            var objOk = obj.replace(/'/g, "\"");
            var productObj = JSON.parse(objOk);
            
            dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': 'EUR',
                    'add': { //Indica que se agrega un producto al carrito
                        'actionField': {
                            'list': 'Checkout' // Lista de acceso a la ficha
                        },
                        'products': [{
                            'name': productObj.name, // Nombre del producto
                            'id': productObj.id, // ID del producto
                            'price': productObj.price, // Precio del producto
                            'brand': productObj.brand, // Marca del producto
                            'category': productObj.category, // Categoría del producto
                            'variant': productObj.variant, // Variante del producto
                            'size': productObj.size, // Variante de talla del producto
                            'quantity': 1 // Cantidad de productos
                        }]
                    }
                }
            });
        }
    }
    if ($('.btn-analytics-del').length > 0) {
        $(document).on('click', '.btn-analytics-del', function (e) {
            // e.stopPropagation();
            productDel($(this).attr('data-analytics'), $(this).attr('data-quantity'));
        });
        function productDel(obj, qty) {
            var objOk = obj.replace(/'/g, "\"");
            var productObj = JSON.parse(objOk);
     
            dataLayer.push({
                'event': 'removeFromCart',
                'ecommerce': {
                    'currencyCode': 'EUR', // Tipo de moneda.
                    'remove': { //Indica que se agrega un producto al carrito
                        'actionField': {
                            'list': 'Checkout' // Lista de acceso a la ficha
                        },
                        'products': [{
                            'name': productObj.name, // Nombre del producto
                            'id': productObj.id, // ID del producto
                            'price': productObj.price, // Precio del producto
                            'brand': productObj.brand, // Marca del producto
                            'category': productObj.category, // Categoría del producto
                            'variant': productObj.variant, // Variante del producto
                            'size': productObj.size, // Variante de talla del producto
                            'quantity': qty // Cantidad de productos
                        }]
                    }
                }
            });
        }
    }
    // fin Google Analytics

    // Botón cerrar alert
    $('.alert .close').click(function (e) {
        $('.alert').slideUp();
    });

    
    // Botón Buscador
    if ($('.post-menu .btn-search').length > 0) {
        $('.post-menu .btn-search').on('click', function (e) {
            if(!$(this).hasClass('in-search')){
                // Cerramos las modales de Favoritos o carrito si estuvieran activas
                resetFav();
                resetCart();

                let _this = $(this);

                // Si la modal está abierta
                if (_this.hasClass('active')) {
                    // Cerramos modal 
                    closeHeadSearch();
                    return;
                } else {
                    $('body').removeClass('layer-hover');
                    $('body').addClass('layer-hover');

                    _this.addClass('active');
                    $('.main-menu .form-search').addClass('active');

                    // Añadimos capa negra con opacidad
                    $('body .dark-layer').remove();
                    $('body').prepend('<div class="dark-layer"></div>');

                    $('body .dark-layer').off();
                    $('body .dark-layer').on('click', function (e) {
                        e.preventDefault();
                        closeHeadSearch();
                    });
                    // Activamos el focus en el campo de búsqueda
                    $('header .main-menu .form-search input[type="text"]').focus();

                }
            }else{
                e.preventDefault();
            }
        });

    }
    function closeHeadSearch() {
        $('body .dark-layer').remove();

        const _this = $('.post-menu .btn-search');
        _this.removeClass('active');
        _this.find('.content').removeClass('active');
        $('.main-menu .form-search').removeClass('active');
        $('body').removeClass('layer-hover');
    }


    // Botón Favoritos
    if ($('.post-menu .btn-favorite').length > 0) {
        $('.post-menu .btn-favorite > a').on('click', function (e) {
            // Cerramos las modales de búsqueda o carrito si estuvieran activas
            resetSearch();
            resetCart();

            let _this = $(this).parent();
            let _content = $(this).parent().find('.content');

            // Si la modal está abierta
            if (_this.hasClass('active')) {
                // $('body').removeClass('layer-hover');
                // Cerramos modal carrito
                closeHeadFavorite();
                return;
            } else {
                $('body').removeClass('layer-hover');
                $('body').addClass('layer-hover');

                _this.addClass('active');
                _content.addClass('active');

                // Añadimos capa negra con opacidad
                $('body .dark-layer').remove();
                $('body').prepend('<div class="dark-layer"></div>');

                $('body .dark-layer').off();
                $('body .dark-layer').on('click', function (e) {
                    e.preventDefault();
                    closeHeadFavorite();
                });

            }
        });

        // Añadimos evento al botón cerrar, para cerrar la modal carrito
        $('.btn-favorite .content .close').off();
        $('.btn-favorite .content .close').on('click', function (e) {
            e.preventDefault();
            closeHeadFavorite();
        });

    }
    function closeHeadFavorite() {
        $('body .dark-layer').remove();
        
        const _this = $('.post-menu .btn-favorite .close');
        // _this.find('.content .close').off();
        _this.parent().removeClass('active');
        _this.parent().parent().removeClass('active');
        _this.parent().find('.content').removeClass('active');
        $('body').removeClass('layer-hover');
    }

    // Botón Carrito
    if ($('.post-menu .btn-cart').length > 0) {
        $('.post-menu .btn-cart > a').on('click', function (e) {

            if(!$('.post-menu .btn-cart').hasClass('cart-in')){
                e.preventDefault();

                // Cerramos las modales de búsqueda o favoritos si estuvieran activas
                resetSearch();
                resetFav();

                let _this = $(this).parent();
                let _content = $(this).parent().find('.content');

                // Si la modal está abierta
                if (_this.hasClass('active')) {
                    // Cerramos modal carrito
                    closeHeadCart();
                    return;
                } else {
                    $('body').removeClass('layer-hover');
                    $('body').addClass('layer-hover');

                    _this.addClass('active');
                    _content.addClass('active');

                    // Añadimos capa negra con opacidad
                    $('body .dark-layer').remove();
                    $('body').prepend('<div class="dark-layer"></div>');

                    $('body .dark-layer').off();
                    $('body .dark-layer').on('click', function (e) {
                        e.preventDefault();
                        closeHeadCart();
                    });
                    
                    disebleScrollOnCartLayer();
                }
            }
        });

        // Añadimos evento al botón cerrar, para cerrar la modal carrito
        $('.btn-cart .content .close').off();
        $('.btn-cart .content .close').on('click', function (e) {
            e.preventDefault();
            closeHeadCart();
        });

        

    }
    // Al abrir la capa carrito, si es versión móvil, bloqueamos el scroll de la página
    function disebleScrollOnCartLayer(){
        if ($(window).width() <= 500) {
            const container = document.querySelector(".main-menu .btn-cart .content");
            disableBodyScroll(container);
        }
    }
    function closeHeadCart(){
        $('body .dark-layer').remove();
        const _this = $('.post-menu .btn-cart .close');
        _this.parent().removeClass('active');
        _this.parent().parent().parent().removeClass('active');
        _this.parent().parent().parent().find('.content').removeClass('active');
        $('body').removeClass('layer-hover');
        bodyScrollLock.clearAllBodyScrollLocks();
    }
    $('.btn-cart .continue').on('click', function (e) {
        e.preventDefault();
        $('.btn-cart').removeClass('active ');
        $('.btn-cart').find('.content').removeClass('active');
        $('body').removeClass('layer-hover');
    });

    if ($('.main-menu .btn-cart .content').length > 0 ) {
        if ($('.main-menu .btn-cart .content').hasClass('active')) {
            disebleScrollOnCartLayer();
        }
    }
    

    function resetSearch() {
        $('.post-menu .btn-search').removeClass('active');
        $('.main-menu .form-search').removeClass('active');
    }
    function resetFav() {
        $('.post-menu .btn-favorite').removeClass('active');
        $('.post-menu .btn-favorite').find('.content').removeClass('active');
    }
    function resetCart() {
        $('.post-menu .btn-cart').removeClass('active');
        $('.post-menu .btn-cart').find('.content').removeClass('active');
    }
    

    ///////////
    // SCROLL
    ///////////

    // Initialize variables
    var lastScrollTop = 0;
   
    // Scrolling
    let up = false;
    window.addEventListener("scroll", function () {
        let st = window.pageYOffset || document.documentElement.scrollTop;

        if (st > 300){
            if (st < lastScrollTop && !up) {
                up = true;
                $('#btn-up').addClass('visible');
            } 
            else if (st > lastScrollTop && up) {
                up = false;
                $('#btn-up').removeClass('visible');
            }
        }else{
            up = false;
            $('#btn-up').removeClass('visible');
        }

        lastScrollTop = st <= 0 ? 0 : st;
    }, false);

    // Top Up Button
    $('#btn-up').on('click', function (event) {
        event.preventDefault();
        $('html,body').animate({
            scrollTop: 0
        }, 'slow');
        return false;
    });


    ///////////
    // FOOTER
    ///////////

    function checkFooter() {
        // Resize
        $('#benefits .slider.slick-initialized').slick('resize');
        // Initialize
        $('#benefits .slider').not('.slick-initialized').slick({
            dots: false,
            slidesToShow: 1,
            arrows: true,
            infinite: true,
            speed: 300,
        });
    }
    
    $('.footer .newsletter .icon-legal').click(function (e) {
        $(this).toggleClass('active');
        $('.footer .newsletter .legal-wrapper').slideToggle();
    });

    if ($('.footer .newsletter .newsletter-result').length > 0) {
        if (!$('.footer .newsletter .newsletter-result').is(':empty')) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(".footer .newsletter .newsletter-result").offset().top - 300
            }, 600);
        }
    }
    

    /////////////////
    // FORMULARIOS
    /////////////////
    if($('form').length > 0) {
        $('form').find('input[type="tel"], input[type="text"], input[type="password"]').parent().addClass('label');

        // Si algún campo imput tiene valor, añadimos clase al label para que se posicione sin taparlo
        $('form').find('input[type="tel"], input[type="text"], input[type="password"]').each((e,ele)=>{
            setTimeout(function(){
                if ($(ele).hasClass('has-value') || $(ele).val()) {
                    $(ele).siblings('label').addClass('active');
                }
            },300)
        })
        // On focus On / Out
        $(this).find('input[type="tel"], input[type="text"], input[type="password"]').focusin(
            function () {
                $(this).siblings('label').addClass('active');
            }).focusout(
            function () {
                if ($(this).val() == ''){
                    $(this).siblings('label').removeClass('active');
                }
            }
        );
        $(this).find('input[type="tel"], input[type="text"], input[type="password"]').click(function () {
            $(this).siblings('label').addClass('active');
        });
        $(this).find('input[type="tel"], input[type="text"], input[type="password"]').keypress(function () {
            if(!$(this).siblings('label').hasClass('active')){
                $(this).siblings('label').addClass('active');
            }
        });
    }

    // Sustituimos los selects por el formato ul>li para poder darle estilos
    $('select').each(function () {
        var $this = $(this), numberOfOptions = $(this).children('option').length, clase = '';
        if($this.hasClass('disabled'))
            clase = ' disabled';
        $this.addClass('select-hidden');
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled' + clase + '"></div>');

        var $styledSelect = $this.next('div.select-styled');
        var selected = $this.children('option').eq(0).text();

        if ($this.children('option[selected="selected"]').length > 0 && ($('#country-id').length > 0 || $('#customeraddress-state').length > 0)) {
            selected = $this.children('option[selected="selected"]').text();            
        }
        $styledSelect.text(selected);

        var $list = $('<ul />', {
            'class': 'select-options'
        }).insertAfter($styledSelect);

        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val(),
                'data-cart': $this.children('option').eq(i).attr('data-cart'),
                'data-url': $this.children('option').eq(i).attr('data-url'),
                'data-id': $this.children('option').eq(i).attr('data-id'),
                class: $this.children('option').eq(i).attr('class')
            }).appendTo($list);
        }

        var $listItems = $list.children('li');
        if (i > 1) {
            $styledSelect.click(function (e) {
                e.stopPropagation();     
                if($(this).hasClass('disabled'))
                    return false;           
                $('div.select-styled.active').not(this).each(function () {
                    $(this).removeClass('active').next('ul.select-options').hide();
                });
                $(this).toggleClass('active').next('ul.select-options').toggle();
            });
        } else {
            return false;
        }

        $listItems.click(function (e) {
            e.stopPropagation();
            if($(this).hasClass('disabled'))
                return false;
            // Selector tallas
            if($('.size-selector').length > 0){
                // ajax
                
                // $('.actions-block .action.add-cart').attr('href', $(this).attr('data-cart'));
                // $.get("/product/available/" + $(this).attr('data-id'), function (data, status) {
                //     // está disponible activamos botón añadir a cesta y quitamo el de aviso
                //     if(data == 1){
                //         $('.action.add-cart').removeClass('hide');
                //         $('.action.notify').addClass('hide');
                //         $('.btn-add-cart').removeClass('hide');
                //         $('.btn-notify').addClass('hide');   
                //     // al reves
                //     }else{
                //         $('.action.add-cart').addClass('hide');
                //         $('.action.notify').removeClass('hide');
                //         $('.btn-add-cart').addClass('hide');
                //         $('.btn-notify').removeClass('hide');
                //     }
                // });
                try{
                    sessionStorage.setItem("sizeSelected", "true");
                }catch(e){
                    console.log(null);
                }
                window.location.replace($(this).attr('data-url'));
            }

            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
            $this.trigger('change');
        });

        $('.select').on('click', 'li', function (e) {
            e.stopPropagation();
            if ($(this).hasClass('disabled'))
                return false;

            $(this).parent().parent().find('.select-styled').text($(this).text()).removeClass('active');
            $(this).val($(this).attr('rel'));
            $('.select-options').hide();
            if ($('#customeraddress-state').length>0)
            $('#customeraddress-state').val($(this).attr('rel'));
        });

        $(document).click(function () {
            $styledSelect.removeClass('active');
            $list.hide();
        });

    });

    // Menú responsive
    let responsiveType;
    // Establecemos el tipo de resolución y cambiamos los elementos del menú para móvil o desktop
    if ($(window).width() <= 750) {
        responsiveType = 'mobile';
        prepareMenuResponsive();
    } else if (($(window).width() <= 1024 && $(window).width() > 750)) {
        responsiveType = 'tablet';
        prepareMenuResponsive();
    } else {
        responsiveType = 'desktop';
        prepareMenuDesktop();
    }

    // Event resize window
    $(window).on('resize', function () {
        if ($(window).width() <= 750 && responsiveType != 'mobile') {
            responsiveType = 'mobile';
            prepareMenuResponsive();
            checkFooter();
        } else if (($(window).width() <= 1024 && $(window).width() > 750) && responsiveType != 'tablet') {
            responsiveType = 'tablet';
            prepareMenuResponsive();
            checkFooter();
        } else if ($(window).width() > 1024 && responsiveType != 'desktop') {
            responsiveType = 'desktop';
            prepareMenuDesktop();
            checkFooter();
        }
    });

    checkFooter();

    function prepareMenuResponsive(){
        // Cambiamos el pre-menu de posición, para el menú responsive
        // $('header .pre-menu').insertAfter('header .primary-menu .menu');
        $('header .pre-menu').appendTo('header .primary-menu');
    }
    
    let btnActive;
    let oldBtnActive;
    let subActive;
    let oldSubActive;

    function prepareMenuDesktop(){

        // Cambiamos el pre-menu de posición, para el menú normal - desktop
        $('header .pre-menu').insertBefore('header .main-menu');
        
        // Quitarmos las clases y estilos del menú responsive
        if (btnActive)
            btnActive.removeClass('show-menu');

        if (subActive){
            subActive.removeAttr('style');
        }
        $('.primary-menu').removeAttr('style');
    }
    
    // Mostrar / Ocultar menú responsive
    $('.btn-menu-responsive').click(function (e) {
        e.stopPropagation();
        e.preventDefault();
        const menu = document.querySelector(".primary-menu");
        if($(this).hasClass('active')){
            $(this).removeClass('active');
            $('.primary-menu').slideUp();
            $('.primary-menu').removeClass('active');
            enableBodyScroll(menu);
        }else{
            $(this).addClass('active');
            $('.primary-menu').slideDown();
            $('.primary-menu').addClass('active');
            disableBodyScroll(menu);
        }
    });

    // Funcionalidad para evitar que en dispositivos móviles, las secciones principales del menú - menu NO Hamburguesa - , no naveguen hacia la sección se el submenú no está desplegado.
    // De esta forma, el primer clic del usuario desplegará el submenú y el segundo clic (con el submenú abierto) navegará hacia la sección.
    let menuHoverActive;
    let premenuHoverActive;
    if ($(window).width() > 1024 && isMobileDevice()) {
        $('header .main-menu .primary-menu .menu > li > a').on('click', function (e) {
            const index = $(this).parent().index();
            if (index != menuHoverActive) {
                e.preventDefault();
                menuHoverActive = $(this).parent().index();
            }
        });
        $('header .pre-menu > ul > li.have-sub a').on('click', function (e) {
            const index = $(this).parent().index();
            if (index != premenuHoverActive) {
                e.preventDefault();
                premenuHoverActive = $(this).parent().index();
            }
        });
    }

    
    // Hover menú principal
    $('header .main-menu .primary-menu .menu > li').hover(
        (e) => {
            if ($(window).width() > 1024) {
                $('body').addClass('layer-hover');
                closeHeadFavorite();
                closeHeadSearch();
                closeHeadCart();
            }
        }, function () {
            if ($(window).width() > 1024){
                $('body').removeClass('layer-hover');
            }
        }
    );

    // Btns tipo acordeón del menú principal
    $('header .main-menu .primary-menu .menu > li > a').click(function(e){
        if ($(window).width() <= 1024) {
            e.stopPropagation();
            e.preventDefault();
            let same = false;
            // Si ya había un menú desplegado - oculatmos el aterior y mostramos el siguiente
            if (btnActive){
                // Hemos clicado en el mismo menú
                if ($(this).hasClass('show-menu')){
                    btnActive.removeClass('show-menu');
                    subActive.slideUp();
                    same = true;
                }
                // Clic en otro menú 
                else{
                    btnActive.removeClass('show-menu');
                    subActive.slideUp();
                    oldBtnActive = btnActive;
                    oldSubActive = subActive;
                }
            }
            // Si hemos clicado en el mismo menú, ocultamos el submenú
            if(!same){
                btnActive = $(this);
                subActive = $(this).parent().find('.item-content');
                subActive.slideToggle();
                btnActive.addClass('show-menu');
            }
        }
    });

    $('header .pre-menu li.have-sub > a').click(function(e){
        if ($(window).width() <= 1024) {
            e.stopPropagation();
            e.preventDefault();

            let same = false;

            if (btnActive) {
                if ($(this).hasClass('show-menu')) {
                    btnActive.removeClass('show-menu');
                    subActive.slideUp();
                    same = true;
                } else {
                    btnActive.removeClass('show-menu');
                    subActive.slideUp();
                    oldBtnActive = btnActive;
                    oldSubActive = subActive;
                }
            }

            if (!same) {
                btnActive = $(this);
                subActive = $(this).parent().find('.sub-menu');
                subActive.slideToggle();
                btnActive.addClass('show-menu');
            }
        }
    });

    $('header .pre-menu li.languages > a.lang-selected').click(function(e){
        if ($(window).width() <= 1024) {
            e.stopPropagation();
            e.preventDefault();

            let same = false;

            if (btnActive) {
                if ($(this).hasClass('show-menu')) {
                    btnActive.removeClass('show-menu');
                    subActive.slideUp();
                    same = true;
                } else {
                    btnActive.removeClass('show-menu');
                    subActive.slideUp();
                    oldBtnActive = btnActive;
                    oldSubActive = subActive;
                }
            }

            if (!same) {
                btnActive = $(this);
                subActive = $(this).parent().find('.dropdown-menu');
                subActive.slideToggle();
                btnActive.addClass('show-menu');
            }
        }
    });

    // Funcionalidad para mostar contraseña en los campos input type password
    if($('input[type=password]').length > 0){

        $('input[type=password]').each(function () {
            $(this).parent().prepend('<button class="btn-eye icon-eye" type="button" tabindex="-1"></button>');
            $(this).addClass('pass');
        });

        $('.btn-eye').on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            var inp = $(this).parent('.form-group').find('.pass');

            var inputType = inp.attr("type");
            // var buttonText = "Hide";
            var setType = "";
            if (inputType == "password") {
                setType = "text";
                $(this).addClass('active');
            } else {
                setType = "password";
                // buttonText = "Show";
                $(this).removeClass('active');
            }

            inp.attr("type", setType);
            // button.innerHTML = buttonText;
        });
    }

});